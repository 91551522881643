import { Alert, Battery, Card, Typography } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import classNames from 'classnames';
import { useContext } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import { ModuleName } from '../../types';
import { moduleAvailable } from '../../utils/utils';
import { ModuleToggle } from '../ModuleToggle/ModuleToggle';
import styles from './ModuleCard.module.css';

type ModuleCardProps = {
  name: ModuleName;
  children: React.ReactNode;
  i18nScope: 'modules.priceOptimization';
  smartMeterRequired?: boolean;
};

export const ModuleCard = ({ name, children, i18nScope, smartMeterRequired }: ModuleCardProps) => {
  const { t } = useTranslation();
  const { offerLoading, offer } = useContext(OfferContext);
  const { modules } = useContext(AppContext);
  const selected = modules.has(name);
  const available = moduleAvailable(offer, name);

  const unavailableLocaleKey =
    offer?.isNotAllowedToIncludeBatteryOptimizationModuleDueToChargerOrHeatPump &&
    name === ModuleName.PRICE_OPTIMIZATION
      ? 'unavailableBecauseChargerOrHeatPump'
      : 'unavailable';

  const toggleCta = parseHtmlTags(
    t(`${i18nScope}.${!available ? unavailableLocaleKey : 'toggleCta'}`),
  );

  return (
    <Card
      paddingSize="md"
      className={classNames(styles.moduleCard, {
        [styles.selected]: selected,
        [styles.unavailable]: !available,
      })}
      dataTestId={`${name}-module`}
    >
      <Typography.Body1>{t(`${i18nScope}.cardHeader`)}</Typography.Body1>

      {children}

      <div className={classNames(styles.moduleSelection, { [styles.offerLoading]: offerLoading })}>
        <div className={styles.toggleCta}>
          {!available && <Battery />}
          <Typography.Body1>{toggleCta}</Typography.Body1>
        </div>
        <ModuleToggle name={name} />
      </div>
      {smartMeterRequired && (
        <Alert variant="warning" title={t(`${i18nScope}.smartMeterInfo.title`)}>
          {parseHtmlTags(t(`${i18nScope}.smartMeterInfo.content`))}
        </Alert>
      )}
    </Card>
  );
};
