import { Button, Card, Checkbox, Typography } from '@sonnen/web-ui';
import classNames from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import AddressSection from '../../components/AddressSection/AddressSection';
import { DataSection } from '../../components/DataSection/DataSection';
import { OrderFlowPage } from '../../components/OrderFlowPage/OrderFlowPage';
import PersonalDataSection from '../../components/PersonalDataSection/PersonalDataSection';
import { CurrentUserAccountBillingAddress, ProviderChangeReason } from '../../graphql/generated';
import { useOrderFlowNavigation } from '../../hooks/useOrderFlowNavigation';
import { useTopScrollNavigate } from '../../hooks/useTopScrollNavigate';
import { TValidationSchema, useValidation } from '../../hooks/useValidation';
import { useTranslation } from '../../i18n/i18n';
import { AppContextType, TAddress, TFormValues, TPersonalData } from '../../types';
import {
  businessAccount,
  completeAddress,
  getFullName,
  getValidDeliveryAddress,
} from '../../utils/utils';
import {
  accountOwnerValidator,
  addressValidator,
  ibanValidator,
  meterValidator,
  personalDataValidator,
  validateExtendedPersonalData,
} from '../../utils/validation';
import commonStyles from '../Common.module.css';
import styles from './DataEntry.module.css';
import { DeliveryDetails } from './DeliveryDetails/DeliveryDetails';
import { PaymentDetails } from './PaymentDetails/PaymentDetails';

const DataEntry = () => {
  const navigate = useTopScrollNavigate();
  const { t } = useTranslation();
  const {
    user,
    formValues,
    accountType,
    setFormValues,
    selectedSite,
    offerRequiresAddress,
    customBillingAddress,
    setCustomBillingAddress,
  } = useContext<AppContextType>(AppContext);
  const [localValues, setLocalValues] = useState<TFormValues>({
    providerChangeReason: undefined,
    ...formValues,
  });
  const { next: nextRoute } = useOrderFlowNavigation();

  const customerAddress = getValidDeliveryAddress(user, selectedSite);
  const customerWithCompleteAddress = addressValidator(customerAddress);
  const customerWithCompletePersonalData = personalDataValidator(user?.contact);
  const customerWithCompleteBillingAddress =
    user &&
    user.account.billingAddress &&
    addressValidator(completeAddress(user.account.billingAddress));

  const readonlyFields: (keyof TAddress)[] = ['postalCode'];

  if (offerRequiresAddress) readonlyFields.push('city');

  if (!customerWithCompleteAddress) {
    if (customerAddress?.city) readonlyFields.push('city');
    if (customerAddress?.streetName) readonlyFields.push('streetName');
    if (customerAddress?.streetNumber) readonlyFields.push('streetNumber');
  }

  const readonlyFieldsBilling: Set<keyof TAddress> = new Set();
  if (user && !customerWithCompleteBillingAddress) {
    if (user?.account.billingAddress?.postalCode) readonlyFieldsBilling.add('postalCode');
    if (user?.account.billingAddress?.city) readonlyFieldsBilling.add('city');
    if (user?.account.billingAddress?.streetName) readonlyFieldsBilling.add('streetName');
    if (user?.account.billingAddress?.streetNumber) readonlyFieldsBilling.add('streetNumber');
  }

  const showAddressSection =
    (!user && customBillingAddress) || (user && !customerWithCompleteBillingAddress);

  const validatePersonalData = (personalData: TPersonalData | undefined) => {
    return validateExtendedPersonalData(personalData, Boolean(user), businessAccount(accountType));
  };

  const requireCancellationAndStartDates =
    localValues.contractTerminated &&
    (Boolean(user) || localValues.providerChangeReason === ProviderChangeReason.Relocation);

  const schema: TValidationSchema<TFormValues> = useMemo(
    () => ({
      personalData: {
        required: true,
        validator: validatePersonalData,
        message: t('data.personalDataError'),
      },
      deliveryAddress: {
        required: true,
        message: t('data.address.addressError'),
        validator: addressValidator,
      },
      billingAddress: {
        required: customBillingAddress || (!!user && !customerWithCompleteBillingAddress),
        message: t('data.address.addressError'),
        validator: addressValidator,
      },
      providerChangeReason: {
        required: !user,
        message: t('data.deliveryDetails.providerChangeReasonError'),
      },
      meterId: {
        required: true,
        message: t('data.deliveryDetails.meterIdError'),
        validator: meterValidator,
      },
      confirmedCancellationDate: {
        required: requireCancellationAndStartDates,
        message: t('data.deliveryDetails.confirmedCancellationDateError'),
      },
      terminatedEnergyProviderName: {
        required:
          !localValues.contractTerminated &&
          (Boolean(user) ||
            localValues.providerChangeReason === ProviderChangeReason.SupplierChange),
        message: t('data.deliveryDetails.currentEnergyProviderError'),
      },
      deliveryStartDate: {
        required: requireCancellationAndStartDates,
        message: t('data.deliveryDetails.deliveryStartDateError'),
      },
      iban: {
        required: true,
        message: t('data.paymentDetails.ibanError'),
        validator: ibanValidator,
      },
      accountOwner: {
        required: true,
        message: t('data.paymentDetails.accountOwnerError'),
        validator: accountOwnerValidator,
      },
      paymentAuthorization: {
        required: true,
        message: t('data.paymentDetails.paymentAuthorizationError'),
      },
      termsAndConditions: {
        required: true,
        message: t('data.paymentDetails.termsAndConditionsError'),
      },
    }),
    [localValues.contractTerminated, localValues.providerChangeReason, user, t],
  );

  const [validate, { errors, setErrors }] = useValidation({
    values: localValues,
    lazy: true,
    schema,
  });

  const onChange = (field: string, value: string | boolean | Date | null) => {
    setLocalValues({ ...localValues, [field]: value });
  };

  const onChanges = (newValues: Partial<TFormValues>) => {
    setLocalValues({ ...localValues, ...newValues });
  };

  const onChangePersonalDataOrAddress = (field: string, values: TPersonalData | TAddress) => {
    let newValues = { ...localValues, [field]: values };

    if (field === 'personalData') {
      const accountOwner = getFullName(values as TPersonalData);
      if (accountOwner) {
        newValues = { ...newValues, accountOwner };
        validate(['accountOwner'], newValues);
      }
    }
    setLocalValues(newValues);
  };

  const onClick = () => {
    setFormValues(localValues);
    const validForm = validate(Object.keys(localValues), localValues);
    if (validForm && nextRoute) navigate(nextRoute, { state: 'data-entry' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <OrderFlowPage
      title={t('data.dataEntry.title')}
      className={styles.dataEntryPage}
      dataTestId="data-entry"
    >
      {customerWithCompletePersonalData ? (
        <DataSection<TPersonalData>
          fields={['companyName', 'firstName', 'lastName', 'email', 'phone']}
          i18nScope="data.personalInfo"
          dataTestId="personal-info"
          source={formValues.personalData}
        />
      ) : (
        <PersonalDataSection
          onChange={onChangePersonalDataOrAddress}
          defaultValues={formValues.personalData}
          revalidate={Boolean(errors.personalData)}
        />
      )}

      {customerWithCompleteAddress ? (
        <DataSection<TAddress>
          fields={['streetName', 'streetNumber', 'postalCode', 'city']}
          i18nScope="data.address"
          dataTestId="delivery-address"
          source={formValues.deliveryAddress}
        />
      ) : (
        <Card paddingSize="md" className={commonStyles.formSection}>
          <AddressSection
            onChange={onChangePersonalDataOrAddress}
            name="deliveryAddress"
            defaultValue={formValues.deliveryAddress}
            readonlyFields={readonlyFields}
            revalidate={Boolean(errors.deliveryAddress)}
            notification={user ? t('data.address.incompleteDataNotification') : ''}
          />
          {!user && (
            <Checkbox
              onChange={() => setCustomBillingAddress(!customBillingAddress)}
              name="customBillingAddress"
              checked={customBillingAddress}
              dataTestId="billing-address"
              className={classNames(styles.checkBox, styles.removeBottomSectionSpace)}
              clickableLabel
            >
              <Typography.Body2>{t('data.address.customBillingAddress')}</Typography.Body2>
            </Checkbox>
          )}
        </Card>
      )}

      {customerWithCompleteBillingAddress && (
        <DataSection<CurrentUserAccountBillingAddress>
          fields={['streetName', 'streetNumber', 'postalCode', 'city']}
          i18nScope="data.address"
          name="billingAddress"
          dataTestId="readonly-billing-address"
          source={formValues.billingAddress}
        />
      )}

      {showAddressSection && (
        <Card paddingSize="md" className={commonStyles.formSection}>
          <AddressSection
            onChange={onChangePersonalDataOrAddress}
            name="billingAddress"
            defaultValue={formValues.billingAddress}
            revalidate={Boolean(errors.billingAddress)}
            readonlyFields={Array.from(readonlyFieldsBilling)}
            notification={
              user && !customerWithCompleteBillingAddress
                ? t('data.address.incompleteBillingAddressNotification')
                : ''
            }
          />
        </Card>
      )}

      <DeliveryDetails
        values={localValues}
        onChange={onChange}
        onChanges={onChanges}
        validate={validate}
        errors={errors}
        setErrors={setErrors}
      />

      <PaymentDetails
        values={localValues}
        onChange={onChange}
        validate={validate}
        errors={errors}
        setErrors={setErrors}
      />

      <Card paddingSize="md">
        <Button
          label={t('data.continueAndReview')}
          onClick={onClick}
          dataTestId="continue-btn"
          expanded
        />
      </Card>
    </OrderFlowPage>
  );
};

export default DataEntry;
