import { LocaleKeyOf, useTranslation as useActualTranslation } from '@sonnen/web-utils';
import * as en from '../locales/en-GB.json';

export const useTranslation = () => {
  const typedReturn = useActualTranslation<typeof en>();
  return {
    ...typedReturn,
    language: typedReturn.language as 'de' | 'en-GB',
  };
};

export type LocaleKey = LocaleKeyOf<typeof en>;
