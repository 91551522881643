import { useContext } from 'react';
import { useTranslation } from '../../../i18n/i18n';
import { OfferContext } from '../../../OfferContext/OfferContext';
import { formatCurrency } from '../../../utils/formatting';
import ModuleRow from '../ModuleRow/ModuleRow';

export const PriceOptimizationModuleRow = () => {
  const { offer } = useContext(OfferContext);
  const { t } = useTranslation();

  if (!offer?.configurationWithBatteryOptimizationModule) return false;

  const { dayAheadPriceBenefitDetailsAmount } =
    offer.configurationWithBatteryOptimizationModule.batteryOptimizationModule;

  return (
    <ModuleRow
      dataTestId="price-optimization-module"
      name={t('modules.priceOptimization.title')}
      value="0"
      currency={`€/${t('offer.month')}`}
      description={t(`modules.priceOptimization.priceCard2Caption`, {
        value: formatCurrency(dayAheadPriceBenefitDetailsAmount, 'euro', true),
      })}
    />
  );
};
