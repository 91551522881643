import { Card, Typography } from '@sonnen/web-ui';
import { LocaleKey, useTranslation } from '../../i18n/i18n';
import { formatIBAN } from '../../utils/formatting';
import { getSalutation, localeDateString, useSalutations } from '../../utils/utils';
import { DataRow } from '../DataRow/DataRow';
import styles from './DataSection.module.css';

interface DataSectionProps<T> {
  fields: (keyof T)[];
  i18nScope:
    | 'review.deliveryDetails'
    | 'data.billingAddress'
    | 'data.personalInfo'
    | 'data.address'
    | 'data.paymentDetails';
  source?: T | null;
  dataTestId?: string;
  children?: React.ReactNode;
  name?: 'deliveryAddress' | 'billingAddress';
}

export const DataSection = <T extends object>({
  fields,
  i18nScope,
  source,
  dataTestId,
  children,
  name,
}: DataSectionProps<T>) => {
  const { t } = useTranslation();
  const salutations = useSalutations();

  const sectionTitle = t(
    `${i18nScope}.${name ? `${name}SectionTitle` : 'sectionTitle'}` as LocaleKey,
  );

  const getRow = (field: keyof T) => {
    if (!source || !source[field]) return undefined;

    const fieldValue = source[field];
    let formattedFieldValue: string = '';

    if (typeof fieldValue === 'string') {
      switch (field) {
        case 'iban':
          formattedFieldValue = formatIBAN(fieldValue);
          break;
        case 'salutation':
          formattedFieldValue = getSalutation(salutations, fieldValue)?.value || fieldValue;
          break;
        default:
          formattedFieldValue = fieldValue;
      }
    } else if (fieldValue instanceof Date) {
      formattedFieldValue = localeDateString(fieldValue);
    } else {
      window.Sentry?.reportError(
        `Received unexpected type ${typeof fieldValue} for field ${String(field)}`,
      );
    }

    return (
      <DataRow
        key={String(field)}
        label={t(String(`${i18nScope}.${field.toString()}`) as LocaleKey)}
        value={formattedFieldValue}
        dataTestId={String(field)}
      />
    );
  };

  return (
    <Card paddingSize="md" className={styles.dataSection} dataTestId={dataTestId}>
      <Typography.H3>{sectionTitle}</Typography.H3>
      <div className={styles.prefilledCard} data-testid="filled">
        {children}
        {Object.values(fields).map(getRow)}
      </div>
    </Card>
  );
};
