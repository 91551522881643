import { useCallback, useEffect, useState } from 'react';

export type TValidationSchema<T extends object> = {
  [key in keyof T]?: {
    required: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validator?: (value: any) => boolean;
    message?: string;
  };
};

export type TValidationResult<T extends object> = {
  [key in keyof T]?: string;
};

interface UseValidationProps<T extends object> {
  values: T;
  schema: TValidationSchema<T>;
  lazy?: boolean;
}

type UseValidationReturn<T extends object> = [
  (fields?: string[], newValues?: T) => boolean,
  {
    errors: TValidationResult<T>;
    setErrors: (errors: TValidationResult<T>) => void;
  },
];

/**
 * Implement a custom hook that validates values based on the given schema.
 * @param values the key value pairs to validate.
 * @param schema object with validation rules for each field.
 * @param lazy when true, validation is only triggered by calling the method manually.
 * @returns validate Function and object with errors & setErrors.
 */
export const useValidation = <T extends object>({
  values,
  schema,
  lazy = false,
}: UseValidationProps<T>): UseValidationReturn<T> => {
  const [errors, setErrors] = useState<TValidationResult<T>>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validate = (field: string, value: any): string => {
    const schemaField = schema[field as keyof T];
    if (!schemaField || !schemaField.required) return '';

    if (schemaField.required && !value) return schemaField.message || 'Field is required';
    if (schemaField.validator !== undefined && !schemaField.validator(value)) {
      return schemaField.message || `${field.toString()} is not valid`;
    }
    return '';
  };

  const validateAll = useCallback(
    (keys?: string[], newValues?: T) => {
      const foundErrors: TValidationResult<T> = keys ? { ...errors } : {};
      const validateFields = (keys || Object.keys(schema)) as (keyof T)[];

      validateFields.forEach((key) => {
        const error = validate(String(key), (newValues || values)[key]);
        if (error) {
          foundErrors[key] = error;
        } else {
          delete foundErrors[key];
        }
      });
      setErrors(foundErrors);
      return Object.keys(foundErrors).length === 0;
    },
    [values, schema, errors],
  );

  useEffect(() => {
    if (!lazy) validateAll();
  }, [lazy]);

  return [validateAll, { errors, setErrors }];
};
