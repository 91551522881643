import { createContext, useEffect, useMemo, useState } from 'react';
import { DEFAULT_APP_CONTEXT } from '../constants';
import { CurrentUser, CurrentUserSite, CustomerUserAccountType } from '../graphql/generated';
import { AppContextType, ModuleName, TFormValues } from '../types';
import {
  businessAccount,
  completeAddress,
  getDefaultSite,
  getFullName,
  getValidDeliveryAddress,
} from '../utils/utils';

export const AppContext = createContext<AppContextType>(DEFAULT_APP_CONTEXT);

type AppContextProviderProps = {
  initialValues?: AppContextType;
  children: React.ReactNode;
};

export const AppContextProvider = ({
  children,
  initialValues = DEFAULT_APP_CONTEXT,
}: AppContextProviderProps) => {
  const [user, setUser] = useState<CurrentUser | null>(initialValues.user);
  const [userProcessing, setUserProcessing] = useState<boolean>(initialValues.userProcessing);
  const [formValues, setFormValues] = useState<TFormValues>(initialValues.formValues);
  const [orderComplete, setOrderComplete] = useState<boolean>(initialValues.orderComplete);
  const [customBillingAddressState, setCustomBillingAddressState] = useState<boolean>(
    initialValues.customBillingAddress,
  );
  const [selectedSite, setSelectedSite] = useState<CurrentUserSite | null>(
    initialValues.selectedSite,
  );
  const [offerRequiresAddress, setOfferRequiresAddress] = useState<boolean>(
    initialValues.offerRequiresAddress,
  );
  const [accountType, setAccountType] = useState<CustomerUserAccountType>(
    CustomerUserAccountType.PrivateCustomer,
  );

  const [modules, setModules] = useState<Set<ModuleName>>(new Set(initialValues.modules));

  const toggleModule = (module: ModuleName) => {
    const newModules = new Set(modules);
    if (modules.has(module)) {
      newModules.delete(module);
    } else {
      newModules.add(module);
    }
    setModules(newModules);
  };

  const addModule = (module: ModuleName) => setModules(new Set(modules).add(module));
  const removeModule = (module: ModuleName) => {
    const newModules = new Set(modules);
    newModules.delete(module);
    setModules(newModules);
  };

  const setCustomBillingAddress = (value: boolean) => {
    if (value && Object.entries(formValues.billingAddress).length === 0) {
      setFormValues({
        ...formValues,
        billingAddress: completeAddress(formValues.deliveryAddress),
      });
    }
    setCustomBillingAddressState(value);
  };

  useEffect(() => {
    if (!user) return;
    const deliveryAddress = getValidDeliveryAddress(user, selectedSite);
    const newFormValues: TFormValues = { ...formValues, deliveryAddress };
    setFormValues(newFormValues);
  }, [selectedSite]);

  useEffect(() => {
    if (!user) return;

    setAccountType(user.account.type);
    const firstSiteWithBatteryAndNoContract = getDefaultSite(user);

    const newFormValues = {
      ...formValues,
      accountOwner: getFullName(user?.contact),
      personalData: {
        ...formValues.personalData,
        firstName: user.contact.firstName ?? '',
        lastName: user.contact.lastName ?? '',
        email: user.contact.email ?? '',
        phone: user.contact.phone ?? '',
      },
      deliveryAddress: getValidDeliveryAddress(user, firstSiteWithBatteryAndNoContract),
      billingAddress: {
        postalCode: user.account.billingAddress.postalCode ?? '',
        city: user.account.billingAddress.city ?? '',
        streetName: user.account.billingAddress.streetName ?? '',
        streetNumber: user.account.billingAddress.streetNumber ?? '',
        country: user.account.billingAddress.country ?? '',
      },
    };

    if (businessAccount(user.account.type)) {
      newFormValues.personalData.companyName = user.account.companyName ?? '';
    }

    setFormValues(newFormValues);

    if (firstSiteWithBatteryAndNoContract) {
      setSelectedSite(firstSiteWithBatteryAndNoContract);
    }
  }, [user]);

  const value: AppContextType = useMemo(() => {
    return {
      accountType,
      addModule,
      formValues,
      modules,
      orderComplete,
      offerRequiresAddress,
      removeModule,
      selectedSite,
      setAccountType,
      setFormValues,
      setModules,
      setOfferRequiresAddress,
      setOrderComplete,
      setSelectedSite,
      setUser,
      setUserProcessing,
      toggleModule,
      user,
      userProcessing,
      customBillingAddress: customBillingAddressState,
      setCustomBillingAddress,
    };
  }, [
    accountType,
    addModule,
    formValues,
    modules,
    offerRequiresAddress,
    orderComplete,
    removeModule,
    selectedSite,
    setAccountType,
    setModules,
    setFormValues,
    setOfferRequiresAddress,
    setOrderComplete,
    setSelectedSite,
    setUser,
    setUserProcessing,
    user,
    userProcessing,
    customBillingAddressState,
    setCustomBillingAddress,
  ]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
