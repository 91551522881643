import { Button, Layout, Spinner, Typography } from '@sonnen/web-ui';
import { useAuth } from '@sonnen/web-utils/hooks';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext/AppContext';
import { AppRoute } from '../../constants';
import { CurrentUser } from '../../graphql/generated';
import { useCurrentUserData } from '../../hooks/useCurrentUserData';
import { useTranslation } from '../../i18n/i18n';
import { AppContextType } from '../../types';

type LoginState = 'loading' | 'loaded' | 'error';

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user: authUser, authenticated, login, loading, error } = useAuth<CurrentUser>();
  const { user } = useContext<AppContextType>(AppContext);

  const [state, setState] = useState<LoginState>('loading');
  const [currentUser, loadingUserData] = useCurrentUserData();

  const loginDeferrer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (currentUser || user) {
      setState('loaded');
      navigate(AppRoute.OFFER_CALCULATION);
    } else if (authUser && !loading && !loadingUserData) {
      window.Sentry?.reportError('Error when logging into Customer Dynamic Tariff');
      setState('error');
    } else {
      setState('loading');
    }
  }, [authUser, currentUser, user, authenticated, loading, loadingUserData]);

  useEffect(() => {
    const okToLogin =
      !authenticated && !loading && !authUser && !window.location.search.includes('code');

    if (okToLogin) {
      setState('loading');
      // Debounce login to allow for the authentication state to be updated
      loginDeferrer.current = setTimeout(() => login(), 100);
    }

    return () => {
      if (loginDeferrer.current) clearTimeout(loginDeferrer.current);
    };
  }, [authenticated, loading, authUser, login]);

  if (error) {
    window.Sentry?.reportError(
      `Error from useAuth when logging into Customer Dynamic Tariff: ${error.message}`,
    );
  }

  const errorMessage = (
    <div
      data-testid="error-message"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 30,
      }}
    >
      <Typography.Label1 variant="alert">{t('login.errorMessage')}</Typography.Label1>
      <Button
        onClick={login}
        label={t('login.retry')}
        size="medium"
        dataTestId="retry-btn"
        variant="secondary"
      />
    </div>
  );

  return (
    <Layout fullViewportHeight centerContent centerVertically>
      {error ? (
        errorMessage
      ) : (
        <div data-testid="login-page">
          {(loading || loadingUserData) && <Spinner size="xl" />}
          {state === 'error' && errorMessage}
        </div>
      )}
    </Layout>
  );
};
