import { Check, Typography, TypographyVariant, WuiColor } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import styles from './CheckmarkLines.module.css';

type Variant = 'default' | 'light';

type CheckmarkLinesProps = {
  list: string[];
  variant?: Variant;
  dataTestId?: string;
};

const CheckmarkLines = ({
  list,
  variant = 'default',
  dataTestId = 'checkmark-lines',
}: CheckmarkLinesProps) => {
  const iconColor: { [key in Variant]: WuiColor } = {
    default: 'primary',
    light: 'neutral',
  };
  const textColor: { [key in Variant]: TypographyVariant } = {
    default: 'primary',
    light: 'on-colour',
  };

  return (
    <div className={styles.checkmarkLines} data-testid={dataTestId}>
      {list.map((item) => (
        <span key={item} className={styles.line}>
          <span>
            <Check color={iconColor[variant]} />
          </span>
          <Typography.Body1 variant={textColor[variant]}>{parseHtmlTags(item)}</Typography.Body1>
        </span>
      ))}
    </div>
  );
};

export default CheckmarkLines;
