import { InputField } from '@sonnen/web-ui';
import { useAuth } from '@sonnen/web-utils/hooks';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppRoute } from '../../constants';
import { usePathname } from '../../hooks/usePathname';
import { OfferContext } from '../../OfferContext/OfferContext';
import styles from './InputField.module.css';

const inputKey = import.meta.env.VITE_HONEYPOT_KEY;

export const waitingTime: { [key: string]: number } = {
  [AppRoute.ROOT]: 2000,
  [AppRoute.OFFER_CALCULATION]: 2000,
  [AppRoute.DATA_ENTRY]: 8000,
};

const HoneypotField = () => {
  const { authenticated } = useAuth();
  const { setWorkEmail } = useContext(OfferContext);
  const [input, setInput] = useState<string>('');
  const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
  const pathname = usePathname();
  const componentMountTimestamp = useRef(Date.now());

  const waitForUser = () => {
    const moreThan3SecTimeLapse =
      new Date().getTime() > componentMountTimestamp.current + waitingTime[pathname];
    // if there are user events, we set the secret key only after 3 or 6 seconds
    if (moreThan3SecTimeLapse && input === '') setWorkEmail(inputKey);
  };

  useEffect(() => {
    if (!authenticated) {
      componentMountTimestamp.current = Date.now();
      events.forEach((userEvent) => window.addEventListener(userEvent, waitForUser));
      return () => {
        events.forEach((event) => window.removeEventListener(event, waitForUser));
      };
    }
  }, [authenticated]);

  useEffect(() => {
    // regardless of when this happens, if input changed, we caught the bot in the Honeypot
    if (input !== '') setWorkEmail(input);
    return () => {
      events.forEach((event) => window.removeEventListener(event, waitForUser));
    };
  }, [input]);

  if (authenticated || navigator.userAgent.includes('Edg')) return;

  return (
    <InputField
      dataTestId="question"
      label=""
      maxLength={60}
      name="question"
      onChange={(_, val) => setInput(val)}
      value={input}
      className={styles.workEmail}
      tabIndex={-1}
      ariaAutocomplete="none"
    />
  );
};

export default HoneypotField;
