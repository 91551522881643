import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { CurrentUserContact } from '../graphql/generated';
import { TAddress, TPersonalData } from '../types';

export const meterValidator = (value: string): boolean => {
  return /^[a-zA-Z0-9]{4,16}$/.test(value);
};

export const postcodeValidator = (value: string): boolean => {
  return value.length >= 5 && /^[0-9]*$/.test(value);
};

export const productConfigurationAddressValidator = (address: TAddress): boolean => {
  if (!address?.postalCode) return false;
  return postcodeValidator(address.postalCode);
};

export const ibanValidator = (value: string): boolean => {
  const iban: string = electronicFormatIBAN(value) as string;
  return isValidIBAN(iban);
};
export const emailValidator = (value: string): boolean => {
  const emailRegEx = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  return emailRegEx.test(value);
};

export const phoneValidator = (value: string): boolean => {
  return /^\d{1,15}$|^\d{1,14}(?: \d{1,14}){1}$/.test(value);
};

export const noEmptySpacesValidation = (value: string): boolean => {
  return /^\S(.*\S)?$/.test(value);
};

export const nameValidator = (value: string): boolean => {
  return noEmptySpacesValidation(value) && /^[\p{Letter}\p{Mark}\s-]+$/gu.test(value);
};

export const commonFieldValidator = (value?: string | null): boolean => {
  return Boolean(value) && Boolean(noEmptySpacesValidation(value!));
};

export const accountOwnerValidator = (value: string): boolean => {
  return commonFieldValidator(value) && value.split(' ').length >= 2;
};

type PersonalDataType = Pick<TPersonalData, 'email' | 'firstName' | 'lastName' | 'phone'>;

export const validateExtendedPersonalData = (
  personalData: TPersonalData | undefined,
  customer: boolean,
  businessAccount: boolean,
) => {
  if (!personalData) return false;
  const requiredFields: (keyof TPersonalData)[] = ['firstName', 'lastName', 'email', 'phone'];
  if (!customer) requiredFields.push('salutation');
  if (businessAccount) requiredFields.push('companyName');
  return requiredFields.every((field) => commonFieldValidator(personalData[field]));
};

export const personalDataValidator = (personalData: CurrentUserContact | undefined): boolean => {
  if (!personalData) return false;
  const requiredFields: (keyof PersonalDataType)[] = ['firstName', 'lastName', 'email', 'phone'];
  return requiredFields.every((field) => commonFieldValidator(personalData[field]));
};

export const visitorPersonalDataValidator = (personalData: TPersonalData): boolean => {
  if (!personalData) return false;
  return personalDataValidator(personalData) && Boolean(personalData.salutation);
};

type AddressSignature = Omit<TAddress, 'country'>;
export const addressValidator = (address: TAddress): boolean => {
  const requiredFields: (keyof AddressSignature)[] = [
    'streetName',
    'city',
    'postalCode',
    'streetNumber',
  ];
  return requiredFields.every((field) => commonFieldValidator(address[field]));
};

export const consumptionValidator = (value: string | number) =>
  !Number.isNaN(Number(value)) && Number(value) > 0 && Number(value) <= 100000;
