import { ProviderChangeReason } from '../../../graphql/generated';
import { LocaleKey } from '../../../i18n/i18n';
import { in20Days, inDays } from '../../../utils/utils';

const { FirstTimeOccupancy, Relocation, SupplierChange } = ProviderChangeReason;

export const getMinimumDeliveryStartDate = (
  providerChangeReason: ProviderChangeReason = SupplierChange,
): Date => {
  const thirtyDaysBefore = inDays(0);
  thirtyDaysBefore.setDate(thirtyDaysBefore.getDate() - 30);
  return providerChangeReason === SupplierChange ? in20Days() : thirtyDaysBefore;
};

export const getMaximumDeliveryStartDate = (
  providerChangeReason: ProviderChangeReason = SupplierChange,
): Date => {
  const daysLater = providerChangeReason === SupplierChange ? 365 : 90;
  return new Date(inDays(0).setDate(inDays(0).getDate() + daysLater));
};

export const getTranslationForNotTerminatedContract = (
  providerChangeReason: ProviderChangeReason = SupplierChange,
): LocaleKey => {
  switch (providerChangeReason) {
    case FirstTimeOccupancy:
    case Relocation:
      return `data.providerChangeReason.${providerChangeReason}.deliveryStartDateInfo`;
    default:
      return 'data.deliveryDetails.earliestDeliveryDateInfo';
  }
};
