import { Typography } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import { useTranslation } from '../../i18n/i18n';
import styles from './USPTypography.module.css';

type USPTypographyProps = {
  i18nScope:
    | 'home.cardChart'
    | 'home.cardSmartMeter'
    | 'home.priceOptimizationTeaserCard'
    | 'home.cardOptimization';
  variant?: 'primary' | 'on-colour';
};

export const USPTypography = ({ i18nScope, variant = 'primary' }: USPTypographyProps) => {
  const { t } = useTranslation();
  const title = t(`${i18nScope}.title`);
  const text = t(`${i18nScope}.text`);
  return (
    <span className={styles.uspTypography}>
      <Typography.H2 variant={variant}>{title}</Typography.H2>
      <p style={{ marginBottom: 'var(--spacing-sm)' }}>
        <Typography.Body1 variant={variant}>
          <span>{parseHtmlTags(text)}</span>
        </Typography.Body1>
      </p>
    </span>
  );
};
