import { Button, LanguageDropdown, Layout, Link, Typography, useMobile } from '@sonnen/web-ui';
import classNames from 'classnames';
import { memo, useContext } from 'react';
import { noBottomCtaRoutes } from '../../constants';
import { usePathname } from '../../hooks/usePathname';
import { useTranslation } from '../../i18n/i18n';
import { SupportedLanguage } from '../../i18n/types';
import { OfferContext } from '../../OfferContext/OfferContext';
import styles from './Footer.module.css';

const languages: { label: string; value: SupportedLanguage }[] = [
  { label: 'Deutsch (DE)', value: 'de' },
  { label: 'English (UK)', value: 'en-GB' },
];

type FooterProps = {
  openCookieBanner: () => void;
};

const Footer = ({ openCookieBanner }: FooterProps) => {
  const { t, language, changeLanguage } = useTranslation();
  const mobile = useMobile();
  const { offer, offerLoading } = useContext(OfferContext);
  const pathname = usePathname();
  const bottomSpace = mobile && (offer || offerLoading) && !noBottomCtaRoutes.includes(pathname);

  return (
    <div
      className={classNames(styles.footer, { [styles.spaceForMobileOffer]: bottomSpace })}
      data-testid="footer"
    >
      <Layout centerVertically>
        <div className={styles.footerItems}>
          <div className={styles.sonnen} data-testid="copyright">
            <Typography.Label2 variant="secondary">{t('footer.sonnen')}</Typography.Label2>
          </div>

          <div className={styles.language}>
            <LanguageDropdown<SupportedLanguage>
              title={t('footer.changeLanguage')}
              languages={languages}
              language={language}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className={styles.links}>
            <Link href="https://sonnen.de/agb" target="_blank" dataTestId="terms-of-use-link">
              {t('footer.termsOfUse')}
            </Link>

            <Button
              variant="tertiary"
              size="small"
              label="Cookies"
              dataTestId="adjust-cookie-consent-btn"
              onClick={openCookieBanner}
              className={styles.cookiesBtn}
            />

            <Link href="https://sonnen.de/impressum" target="_blank" dataTestId="imprint">
              {t('footer.imprint')}
            </Link>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default memo(Footer);
