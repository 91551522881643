import { Alert, Typography } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext/AppContext';
import BenefitCard from '../../../components/BenefitCard/BenefitCard';
import { ModuleCard } from '../../../components/ModuleCard/ModuleCard';
import { OrderFlowPage } from '../../../components/OrderFlowPage/OrderFlowPage';
import { PriceCards } from '../../../components/PriceCards/PriceCards';
import { TerminationPeriod } from '../../../graphql/generated';
import { useTranslation } from '../../../i18n/i18n';
import { OfferContext } from '../../../OfferContext/OfferContext';
import { ModuleName } from '../../../types';
import {
  formatCurrency,
  getCurrencyPropertyValue,
  getTwoDigitsString,
} from '../../../utils/formatting';
import { moduleAvailable } from '../../../utils/utils';
import styles from './PriceOptimization.module.css';

const I18N_SCOPE = 'modules.priceOptimization';

export const PriceOptimization = () => {
  const { t } = useTranslation();
  const { user, modules, setFormValues, formValues } = useContext(AppContext);
  const { offer } = useContext(OfferContext);
  const available = moduleAvailable(offer, ModuleName.PRICE_OPTIMIZATION);

  let firstYearPrice: string | number = getTwoDigitsString('0');
  let priceAfterFirstYear = `${getTwoDigitsString('4,99')} €`;
  let terminationPeriodOption = TerminationPeriod.Termination_30DaysToEndOfMonth;

  if (!offer) return false;

  if (
    modules.has(ModuleName.PRICE_OPTIMIZATION) &&
    offer.configurationWithBatteryOptimizationModule
  ) {
    const {
      dayAheadPriceBaseFeeGrossPerMonth,
      dayAheadPriceBenefitDetailsAmount,
      dayAheadPriceTerminationPeriodOption,
    } = offer.configurationWithBatteryOptimizationModule.batteryOptimizationModule;
    terminationPeriodOption = dayAheadPriceTerminationPeriodOption;

    firstYearPrice =
      getCurrencyPropertyValue(dayAheadPriceBaseFeeGrossPerMonth) -
      getCurrencyPropertyValue(dayAheadPriceBenefitDetailsAmount);
    priceAfterFirstYear = formatCurrency(dayAheadPriceBenefitDetailsAmount, 'euro', true);
  }

  const onHardwareOfferClick = () => {
    const { interestedInHardwareOffer } = formValues;
    setFormValues({
      ...formValues,
      interestedInHardwareOffer: !interestedInHardwareOffer,
    });
  };

  const priceCards = [
    {
      title: t(`${I18N_SCOPE}.priceCard1Title`),
      caption: t(`${I18N_SCOPE}.priceCard1Caption`, {
        value: firstYearPrice,
      }),
    },
    {
      title: t(`${I18N_SCOPE}.priceCard2Title`),
      caption: t(`${I18N_SCOPE}.priceCard2Caption`, {
        value: priceAfterFirstYear,
      }),
    },
  ];

  const unavailableLocaleKey =
    offer.isNotAllowedToIncludeBatteryOptimizationModuleDueToChargerOrHeatPump
      ? 'unavailableBecauseChargerOrHeatPump'
      : 'unavailable';

  return (
    <OrderFlowPage dataTestId="price-optimization" title={t(`${I18N_SCOPE}.title`)}>
      {user && !available && (
        <div className={styles.moduleUnavailable}>
          <Alert
            variant="warning"
            title={t(`modules.${unavailableLocaleKey}.title`)}
            dataTestId="module-unavailable"
          >
            {parseHtmlTags(t(`modules.${unavailableLocaleKey}.content`))}
          </Alert>
        </div>
      )}

      <ModuleCard
        name={ModuleName.PRICE_OPTIMIZATION}
        i18nScope="modules.priceOptimization"
        smartMeterRequired={Boolean(user) && available}
      >
        <PriceCards cards={priceCards} />

        <Typography.Body1>{t(`${I18N_SCOPE}.${terminationPeriodOption}`)}</Typography.Body1>
      </ModuleCard>

      {!user && <BenefitCard onClick={onHardwareOfferClick} />}
    </OrderFlowPage>
  );
};
