import { Sentry } from '@sonnen/web-utils';
import { AuthenticationProvider } from '@sonnen/web-utils/components';
import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppContextProvider } from './AppContext/AppContext';
import { COOKIES_CONSENT_EVENT, COOKIES_CONSENT_KEY } from './constants';
import { useTranslation } from './i18n/i18n';
import { getDefaultLocale, getRedirectUri } from './utils/utils';

const element = document.getElementById('root');
const root = ReactDOM.createRoot(element!);

const Main = () => {
  const apiUrl = import.meta.env.VITE_AUTH_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH_CLIENT_ID;

  const { changeLanguage } = useTranslation();

  if (!apiUrl || !clientId) {
    throw new Error(
      `Missing required environment variables VITE_AUTH_DOMAIN and VITE_AUTH_CLIENT_ID: ${apiUrl}, ${clientId}`,
    );
  }

  const redirectUri = useMemo(() => getRedirectUri(), []);

  const authConfig = {
    autoLogin: false,
    apiUrl,
    clientId,
    redirectUri,
    scope: 'openid',
  };

  const instantiateSentry = () => {
    const appEnv = process.env.VITE_ENV;
    const sentryDns = process.env.VITE_SENTRY_DSN;
    const cookiesConsent = localStorage.getItem(COOKIES_CONSENT_KEY);

    if (appEnv && sentryDns) {
      if (cookiesConsent === 'true') new Sentry(appEnv, sentryDns);
      if (cookiesConsent === 'false') new Sentry(appEnv, sentryDns, false);
    }
  };

  useEffect(() => {
    changeLanguage(getDefaultLocale());

    instantiateSentry();

    window.addEventListener(COOKIES_CONSENT_EVENT, instantiateSentry);
    return () => {
      window.removeEventListener(COOKIES_CONSENT_EVENT, instantiateSentry);
    };
  }, []);

  return (
    <React.StrictMode>
      <AuthenticationProvider config={authConfig}>
        <AppContextProvider>
          <BrowserRouter basename={process.env.VITE_URL_BASE_PATH || '/'}>
            <App />
          </BrowserRouter>
        </AppContextProvider>
      </AuthenticationProvider>
    </React.StrictMode>
  );
};

root.render(<Main />);
