import {
  Button,
  InputField,
  Layout,
  Overlay,
  Select,
  Spinner,
  StepCounter,
  Typography,
  useMobile,
  useMobileAndTablet,
} from '@sonnen/web-ui';
import classNames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../../AppContext/AppContext';
import HoneypotField from '../../../components/HoneypotField/HoneypotField';
import { InfoBanner } from '../../../components/InfoBanner/InfoBanner';
import NoTariffPurchaseModal from '../../../components/NoTariffPurchaseModal/NoTariffPurchaseModal';
import { AppRoute, EMPTY_DELIVERY_ADDRESS, PREDEFINED_CONSUMPTION } from '../../../constants';
import { usePathname } from '../../../hooks/usePathname';
import { useValidation } from '../../../hooks/useValidation';
import { useTranslation } from '../../../i18n/i18n';
import { OfferContext } from '../../../OfferContext/OfferContext';
import { OfferErrorTranslationKey } from '../../../types';
import { getDefaultSite, getGridPurchase, siteToOptionType } from '../../../utils/utils';
import {
  addressValidator,
  consumptionValidator,
  productConfigurationAddressValidator,
} from '../../../utils/validation';
import AdditionalAddress from './AdditionalAddress/AdditionalAddress';
import styles from './OfferCalculationForm.module.css';

const I18N_SCOPE = 'home.calculationForm';

type LayoutWrapperProps = {
  withLayout: boolean;
  children: React.ReactNode;
};

const LayoutWrapper = ({ withLayout, children }: LayoutWrapperProps) =>
  withLayout ? <Layout backgroundColor="none">{children}</Layout> : children;

const scrollToOfferCard = () => {
  const offerCardYOffset = document.getElementById('offer-card')?.offsetTop || 0;
  if (offerCardYOffset > window.innerHeight)
    window.scrollTo({ top: offerCardYOffset - 120, behavior: 'smooth' });
};

const OfferCalculationForm = () => {
  const { t } = useTranslation();
  const pathname = usePathname();
  const homePage = pathname === AppRoute.ROOT;
  const mobile = useMobile();
  const tablet = useMobileAndTablet();

  const {
    formValues,
    offerRequiresAddress,
    selectedSite,
    setFormValues,
    setOfferRequiresAddress,
    setSelectedSite,
    user,
    userProcessing,
  } = useContext(AppContext);

  const {
    deferredOfferLoading,
    offer,
    offerLoading,
    offerError,
    createProductConfigurations,
    productConfigurationsInput,
    setProductConfigurationsInput,
    personsInHousehold,
    setPersonsInHousehold,
  } = useContext(OfferContext);

  const { expectedElectricityConsumptionInKwh } = productConfigurationsInput;

  const [validate, { errors, setErrors }] = useValidation({
    lazy: true,
    values: productConfigurationsInput,
    schema: {
      deliveryAddress: {
        required: true,
        message: t(`${I18N_SCOPE}.postcode.error`),
        validator: productConfigurationAddressValidator,
      },
      expectedElectricityConsumptionInKwh: {
        required: true,
        message: t(
          `${I18N_SCOPE}.consumption.${expectedElectricityConsumptionInKwh > 0 ? 'error' : 'missing'}`,
        ),
        validator: consumptionValidator,
      },
    },
  });

  const sites = user?.sites;

  const gridPurchase: number = useMemo(() => getGridPurchase(selectedSite), [selectedSite]);
  const showStepper = !(userProcessing || gridPurchase || sites?.length);

  const allSitesHaveContract = Boolean(
    sites?.every((site) => !site.options.isAllowedToCreateContract),
  );
  const showNoTariffPurchaseModal = Boolean(
    user && (!selectedSite?.options.isAllowedToCreateContract || allSitesHaveContract),
  );

  const submitButtonDisabled = Boolean(
    errors.expectedElectricityConsumptionInKwh ||
      (offerRequiresAddress && !addressValidator(formValues.deliveryAddress)),
  );

  const onExpectedElectricityConsumptionChange = (_: string, value: string) => {
    setProductConfigurationsInput({
      ...productConfigurationsInput,
      expectedElectricityConsumptionInKwh: parseInt(value, 10),
    });
  };

  const onPostalCodeChange = (_: string, postalCode: string) => {
    setOfferRequiresAddress(false);

    setFormValues({
      ...formValues,
      deliveryAddress: {
        ...EMPTY_DELIVERY_ADDRESS,
        postalCode,
      },
    });

    setProductConfigurationsInput({
      ...productConfigurationsInput,
      deliveryAddress: {
        ...productConfigurationsInput.deliveryAddress,
        postalCode,
      },
    });

    setErrors({ ...errors, deliveryAddress: '' });
  };

  const onSelectedSiteChange = (_: string, value: string) => {
    if (value) {
      const newSite = sites?.find((site) => site.id === value);
      setSelectedSite(newSite || null);
    }
  };

  const onBlur = () => validate();

  const onStepCounterChange = (value: number) => {
    const newConsumption = PREDEFINED_CONSUMPTION[value] || expectedElectricityConsumptionInKwh;

    setPersonsInHousehold(value);
    setProductConfigurationsInput({
      ...productConfigurationsInput,
      expectedElectricityConsumptionInKwh: newConsumption,
    });
  };

  const onSubmit = () => {
    if (validate()) {
      createProductConfigurations({
        ...productConfigurationsInput,
        deliveryAddress: {
          ...productConfigurationsInput.deliveryAddress,
          city: formValues.deliveryAddress.city,
          streetName: formValues.deliveryAddress.streetName,
          streetNumber: formValues.deliveryAddress.streetNumber,
        },
      });
    }
  };

  const generateDefaultSiteOffer = () => {
    setSelectedSite(getDefaultSite(user));
  };

  useEffect(() => {
    if (expectedElectricityConsumptionInKwh) validate(['expectedElectricityConsumptionInKwh']);
  }, [expectedElectricityConsumptionInKwh]);

  useEffect(() => {
    if (offerError !== OfferErrorTranslationKey.POSTCODE_REQUIRES_ADDRESS) return;
    setOfferRequiresAddress(true);
  }, [offerError]);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    if (tablet && offer && !mobile) {
      scrollToOfferCard();
      images.forEach((img) => img.addEventListener('load', scrollToOfferCard));
    }
    return () => images.forEach((img) => img.removeEventListener('load', scrollToOfferCard));
  }, [offer]);

  const siteAddress = selectedSite
    ? `${selectedSite.address.streetName} ${selectedSite.address.streetNumber}, ${selectedSite.address.postalCode} ${selectedSite.address.city}`
    : '';

  return (
    <>
      {showNoTariffPurchaseModal && (
        <NoTariffPurchaseModal
          siteName={siteAddress}
          switchSite={allSitesHaveContract ? undefined : generateDefaultSiteOffer}
        />
      )}

      <section
        className={classNames(styles.offerCalculationForm, {
          [styles.homePagePadding]: homePage,
        })}
        data-testid="offer-calculation-form"
      >
        {userProcessing && (
          <Overlay className={styles.overlayContent} roundedCorners>
            <Spinner size="xl" />
            <Typography.Body1>{t(`${I18N_SCOPE}.userLoadingText`)}</Typography.Body1>
          </Overlay>
        )}

        <LayoutWrapper withLayout={!homePage}>
          {offerError && offerError !== OfferErrorTranslationKey.POSTCODE_REQUIRES_ADDRESS && (
            <div className={styles.configError} data-testid="config-error">
              <Typography.Label2 variant="alert">
                {t(`${I18N_SCOPE}.${offerError}`)}
              </Typography.Label2>
            </div>
          )}

          <div className={styles.formContent}>
            {!user && !userProcessing && (
              <span className={styles.growChild}>
                <InputField
                  dataTestId="postcode"
                  error={errors.deliveryAddress}
                  expanded
                  inputMode="numeric"
                  label={t(`${I18N_SCOPE}.postcode.label`)}
                  maxLength={5}
                  name="postalCode"
                  onBlur={onBlur}
                  onChange={onPostalCodeChange}
                  readOnly={deferredOfferLoading}
                  type="number"
                  autoComplete="on"
                  value={productConfigurationsInput.deliveryAddress.postalCode || ''}
                />
              </span>
            )}

            {sites && sites.length > 1 && (
              <span className={styles.growChild}>
                <Select
                  name="selectedSite"
                  label={t(`${I18N_SCOPE}.selectSite`)}
                  options={sites.map((site) => siteToOptionType(site, t('battery'), t('contract')))}
                  required
                  dataTestId="site-selection"
                  onChange={onSelectedSiteChange}
                  value={
                    selectedSite
                      ? siteToOptionType(selectedSite, t('battery'), t('contract'))
                      : undefined
                  }
                  searchable={false}
                />
              </span>
            )}

            {showStepper && (
              <span className={classNames(styles.growChild, styles.stepper)}>
                <StepCounter
                  disabled={deferredOfferLoading}
                  icon="User"
                  label={t(`${I18N_SCOPE}.stepperLabel`)}
                  max={8}
                  min={1}
                  onChange={onStepCounterChange}
                  value={personsInHousehold}
                />
              </span>
            )}

            <span className={styles.growChild}>
              <InputField
                dataTestId="consumption"
                error={errors.expectedElectricityConsumptionInKwh}
                expanded
                inputMode="numeric"
                label={t(
                  `${I18N_SCOPE}.consumption.${selectedSite ? 'labelForExistingSite' : 'label'}`,
                )}
                maxLength={7}
                name="expectedElectricityConsumptionInKwh"
                onChange={onExpectedElectricityConsumptionChange}
                readOnly={offerLoading || deferredOfferLoading}
                suffix="kWh"
                onBlur={onBlur}
                value={
                  !Number.isNaN(expectedElectricityConsumptionInKwh)
                    ? expectedElectricityConsumptionInKwh.toString()
                    : ''
                }
              />

              {Boolean(gridPurchase) && (
                <div className={styles.consumptionInfo}>
                  <InfoBanner variant="secondary" iconSize="md">
                    <Typography.Label2 variant="secondary">
                      {t(`${I18N_SCOPE}.consumption.info`, { value: gridPurchase })}
                    </Typography.Label2>
                  </InfoBanner>
                </div>
              )}
            </span>

            {offerRequiresAddress && (
              <div className={styles.additionalAddress}>
                <AdditionalAddress />
              </div>
            )}

            <HoneypotField />

            <span className={styles.growChild}>
              <Button
                onClick={onSubmit}
                label={t(`${I18N_SCOPE}.submitBtn`)}
                variant={homePage ? 'primary' : 'secondary'}
                dataTestId="calculate-price-btn"
                loading={offerLoading || deferredOfferLoading}
                disabled={submitButtonDisabled}
                iconRight="ArrowRight"
                expanded
              />
            </span>
          </div>
        </LayoutWrapper>
      </section>
    </>
  );
};

export default OfferCalculationForm;
