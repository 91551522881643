import { Typography } from '@sonnen/web-ui';
import leaf from './leaf.png';
import styles from './StatusTag.module.css';

const StatusTag = ({ text }: { text: string }) => (
  <div className={styles.statusTag} data-testid="status-tag">
    <img src={leaf} className={styles.leafImg} aria-label="green leaf" alt="green leaf" />
    <Typography.Label1>{text}</Typography.Label1>
  </div>
);

export default StatusTag;
