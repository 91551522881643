import { Button, Typography } from '@sonnen/web-ui';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { COOKIES_CONSENT_EVENT, COOKIES_CONSENT_KEY } from '../../constants';
import { useTranslation } from '../../i18n/i18n';
import styles from './CookieBanner.module.css';
import CookieSvg from './CookieSvg';

export const VISIBLE_CLASS_DELAY = 100;
export const UNMOUNT_COMPONENT_DELAY = 300;

type CookieBannerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const CookieBanner = ({ open, setOpen }: CookieBannerProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleClass, setVisibleClass] = useState<boolean>(false);

  const consent = localStorage.getItem(COOKIES_CONSENT_KEY);
  const accepted = consent === 'true';
  const declined = consent === 'false';

  const customEvent = new Event(COOKIES_CONSENT_EVENT);

  const mountWithDelay = () => {
    setVisible(true);
    setTimeout(() => setVisibleClass(true), VISIBLE_CLASS_DELAY);
  };

  const unmountWithDelay = () => {
    setVisibleClass(false);
    setTimeout(() => setVisible(false), UNMOUNT_COMPONENT_DELAY);
  };

  const handleConsent = (value: boolean) => {
    localStorage.setItem(COOKIES_CONSENT_KEY, value.toString());
    setOpen(false);
    unmountWithDelay();
    window.dispatchEvent(customEvent);
  };

  useEffect(() => {
    if (consent === null) setOpen(true);
    if (open) mountWithDelay();
    if (consent !== null && !open) unmountWithDelay();
  }, [open]);

  if (!visible) return null;

  return (
    <div
      data-testid="cookie-banner"
      className={classNames(styles.cookieBanner, { [styles.visible]: visibleClass })}
    >
      <CookieSvg />
      <div>
        <Typography.Body2>{t('cookieBanner.text')}</Typography.Body2>
        <div className={styles.buttons}>
          <Button
            size="small"
            label={t(`cookieBanner.accept${accepted ? 'ed' : ''}Btn`)}
            dataTestId="cookie-accept-btn"
            onClick={() => handleConsent(true)}
          />
          <Button
            variant="tertiary"
            size="small"
            label={t(`cookieBanner.decline${declined ? 'd' : ''}Btn`)}
            dataTestId="cookie-decline-btn"
            onClick={() => handleConsent(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
