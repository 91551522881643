import { Button, Overlay, Spinner, Typography, useMobile } from '@sonnen/web-ui';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from '../../i18n/i18n';
import { OfferContext } from '../../OfferContext/OfferContext';
import { OfferErrorTranslationKey } from '../../types';
import styles from './OfferOverlay.module.css';

type OfferOverlayProps = {
  insideCard?: boolean;
};

const OfferOverlay = ({ insideCard }: OfferOverlayProps) => {
  const { t } = useTranslation();
  const mobile = useMobile();
  const { offerLoading, offerError, refreshOffer, deferredOfferLoading } = useContext(OfferContext);

  const insideCardOnMobileScreen = mobile && insideCard;

  if (!offerLoading && !offerError) return false;

  const errorKey = offerError ?? OfferErrorTranslationKey.SERVER;

  return (
    <Overlay roundedCorners>
      <div
        data-testid="offer-overlay"
        className={classNames(styles.content, {
          [styles.loading]: offerLoading,
          [styles.error]: offerError,
          [styles.card]: mobile && insideCard,
          [styles.mobile]: mobile,
        })}
      >
        {offerLoading && (
          <>
            <Spinner size={mobile ? 'md' : 'lg'} />
            {!insideCardOnMobileScreen && <Typography.Body1>{t('offer.loading')}</Typography.Body1>}
          </>
        )}

        {!offerLoading && offerError && !insideCardOnMobileScreen && (
          <div data-testid={`overlay-error-${offerError}`} className={styles.textAndButton}>
            <Typography.Body1>{t(`offer.${errorKey}`)}</Typography.Body1>

            {errorKey !== OfferErrorTranslationKey.POSTCODE_REQUIRES_ADDRESS && (
              <Button
                variant="secondary"
                label={t('offer.reload')}
                onClick={refreshOffer}
                dataTestId="reload-offer-btn"
                size={mobile ? 'medium' : 'large'}
                loading={deferredOfferLoading}
                expanded
                className="bt"
              />
            )}
          </div>
        )}
      </div>
    </Overlay>
  );
};

export default OfferOverlay;
