import { Alert, Card, Checkbox, DatePicker, InputField, Select, Typography } from '@sonnen/web-ui';
import { parseHtmlTags } from '@sonnen/web-utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContext/AppContext';
import { ProviderChangeReason, useGetEnergyProvidersQuery } from '../../../graphql/generated';
import { TValidationResult } from '../../../hooks/useValidation';
import { useTranslation } from '../../../i18n/i18n';
import { SelectOptionType, TDeliveryDetails, TFormValues } from '../../../types';
import { getActualDeliveryStartDate, localeDateString } from '../../../utils/utils';
import commonStyles from '../../Common.module.css';
import { MeterInfo } from '../MeterInfo/MeterInfo';
import {
  getMaximumDeliveryStartDate,
  getMinimumDeliveryStartDate,
  getTranslationForNotTerminatedContract,
} from './DeliveryDetails.helpers';

const I18N_SCOPE = 'data.deliveryDetails';

const { FirstTimeOccupancy, Relocation, SupplierChange } = ProviderChangeReason;

type DeliveryDetailsProps = {
  values: TDeliveryDetails;
  onChange: (field: string, value: string | boolean | Date | null) => void;
  validate: (fields?: string[] | undefined) => void;
  errors: TValidationResult<TDeliveryDetails>;
  setErrors: (errors: TValidationResult<TDeliveryDetails>) => void;
  onChanges: (newValues: Partial<TFormValues>) => void;
};

type TDeliveryDetailsKey = keyof TDeliveryDetails;

export const DeliveryDetails = ({
  values,
  onChange,
  onChanges,
  validate,
  errors,
  setErrors,
}: DeliveryDetailsProps) => {
  const { user } = useContext(AppContext);
  const [validationQueue, setValidationQueue] = useState<string[]>([]);
  const {
    contractTerminated,
    meterId,
    deliveryStartDate,
    terminatedEnergyProviderName,
    confirmedCancellationDate,
    providerChangeReason,
  } = values;
  const { t } = useTranslation();

  const reasonsForChangeOptions: SelectOptionType<ProviderChangeReason>[] = useMemo(() => {
    return Object.values(ProviderChangeReason).map((reason) => ({
      label: t(`data.providerChangeReason.${reason}.label`),
      value: reason,
    }));
  }, [t]);

  const { data } = useGetEnergyProvidersQuery();
  const energyProviderOptions = data?.energyProviders
    .filter((provider) => Boolean(provider))
    .map((provider) => ({
      label: provider?.name || '',
      value: provider?.name || '',
    }));

  const onDateChange = (field: string, value: Date | string | null) => {
    onChange(field, value);
    setValidationQueue([...validationQueue, field]);
  };

  const onConfirmedCancellationDateChange = (field: string, value: Date | string | null) => {
    const newValues = { ...values, confirmedCancellationDate: value as Date };
    onChanges({ ...newValues, deliveryStartDate: getActualDeliveryStartDate(newValues) });
    setValidationQueue([...validationQueue, field]);
  };

  useEffect(() => {
    if (validationQueue.length) {
      validate(validationQueue as TDeliveryDetailsKey[]);
      setValidationQueue([]);
    }
  }, [values]);

  const onBlurHandler = (fieldName: string) => validate([fieldName]);

  const resetErrors = (field: string) => setErrors({ ...errors, [field]: '' });

  const onChangeHandler = (field: string, value: string | boolean | Date | null) => {
    resetErrors(field);

    switch (field) {
      case 'contractTerminated':
        onChanges({
          contractTerminated: Boolean(value),
          deliveryStartDate: getActualDeliveryStartDate(values),
        });
        return;
      case 'providerChangeReason':
        onChanges({
          providerChangeReason: value as ProviderChangeReason,
          deliveryStartDate: getActualDeliveryStartDate({
            ...values,
            providerChangeReason: value as ProviderChangeReason,
          }),
        });
        return;
      default:
        onChange(field, value);
    }
  };

  const actualDeliveryStartDate = getActualDeliveryStartDate(values);
  const minDeliveryStartDate = getMinimumDeliveryStartDate(providerChangeReason);
  const maxDeliveryStartDate = getMaximumDeliveryStartDate(providerChangeReason);

  const onEnergyProviderChange = (field: string, name: string) => {
    resetErrors(field);
    const energyProvider = data?.energyProviders?.find((provider) => provider?.name === name);
    onChanges({
      terminatedEnergyProviderName: name,
      terminatedEnergyProviderId: energyProvider?.id || '',
    });
  };

  const parsedActualDeliveryStartDateMessage = parseHtmlTags(
    t(`${I18N_SCOPE}.actualDeliveryStartDate`, {
      date: localeDateString(actualDeliveryStartDate),
    }),
  );

  const notTerminatedLocaleKey = getTranslationForNotTerminatedContract(providerChangeReason);

  const desiredStartDatePicker = (
    <DatePicker
      dataTestId="desired-start-date"
      min={minDeliveryStartDate}
      max={maxDeliveryStartDate}
      name="deliveryStartDate"
      onChange={onDateChange}
      onBlur={onBlurHandler}
      value={deliveryStartDate}
      error={errors.deliveryStartDate}
      label={t(`${I18N_SCOPE}.deliveryStartDate`)}
    />
  );

  const instructionalText =
    providerChangeReason && providerChangeReason !== SupplierChange
      ? t(`data.providerChangeReason.${providerChangeReason}.instructionalText`)
      : '';

  return (
    <Card className={commonStyles.formSection} dataTestId="delivery-details" paddingSize="md">
      <Typography.H3>{t(`${I18N_SCOPE}.sectionTitle`)}</Typography.H3>

      {!user && (
        <>
          <Select
            options={reasonsForChangeOptions || []}
            name="providerChangeReason"
            label={t(`data.providerChangeReason.label`)}
            value={reasonsForChangeOptions.find((option) => option.value === providerChangeReason)}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            error={errors.providerChangeReason}
            dataTestId="reason-for-change"
          />
          {providerChangeReason && instructionalText && (
            <div data-testid="instructional-text">
              <Typography.Caption1>{instructionalText}</Typography.Caption1>
            </div>
          )}
        </>
      )}

      <InputField
        name="meterId"
        label={t(`${I18N_SCOPE}.meterId`)}
        value={meterId}
        onChange={onChangeHandler}
        dataTestId="meter-id"
        onBlur={onBlurHandler}
        error={errors.meterId}
        expanded
        maxLength={16}
      />

      <MeterInfo />

      {providerChangeReason === FirstTimeOccupancy && (
        <>
          <Alert
            title={t(`data.providerChangeReason.FIRST_TIME_OCCUPANCY.infoTitle`)}
            variant="info"
            dataTestId="first-time-occupancy-alert"
          >
            {t(`data.providerChangeReason.FIRST_TIME_OCCUPANCY.info`)}
          </Alert>
          {desiredStartDatePicker}
        </>
      )}

      {providerChangeReason === Relocation && desiredStartDatePicker}

      {(user || providerChangeReason === SupplierChange) && (
        <>
          <Checkbox
            onChange={() => onChange('contractTerminated', !contractTerminated)}
            name="contractTerminated"
            checked={contractTerminated}
            dataTestId="contract-terminated"
            clickableLabel
            className={commonStyles.checkBox}
          >
            <Typography.Body2>{t(`${I18N_SCOPE}.contractTerminated`)}</Typography.Body2>
          </Checkbox>

          {contractTerminated ? (
            <DatePicker
              dataTestId="cancellation-confirmed"
              name="confirmedCancellationDate"
              onChange={onConfirmedCancellationDateChange}
              onBlur={onBlurHandler}
              value={confirmedCancellationDate}
              error={errors.confirmedCancellationDate}
              label={t(`${I18N_SCOPE}.confirmedCancellationDate`)}
            />
          ) : (
            <>
              <Select
                options={energyProviderOptions || []}
                name="terminatedEnergyProviderName"
                label={t(`${I18N_SCOPE}.terminatedEnergyProviderName`)}
                value={
                  terminatedEnergyProviderName
                    ? {
                        label: terminatedEnergyProviderName,
                        value: terminatedEnergyProviderName,
                      }
                    : undefined
                }
                onChange={onEnergyProviderChange}
                onBlur={onBlurHandler}
                error={errors.terminatedEnergyProviderName}
                dataTestId="current-energy-provider"
              />
              {desiredStartDatePicker}
            </>
          )}
        </>
      )}

      {providerChangeReason && (
        <span data-testid="actual-delivery-start-date">
          <Typography.Caption1>
            {contractTerminated ? parsedActualDeliveryStartDateMessage : t(notTerminatedLocaleKey)}
          </Typography.Caption1>
        </span>
      )}
    </Card>
  );
};
